import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import NavigationBar, { NavLeft, NavCenter, NavRight } from './NavigationBar';
import Navigation, { NavToggle } from './Navigation';
import NavigationUser from './NavigationUser';
import { MenuToggle, MenuSheet, MenuButton } from './Menu';
import { Button } from '../Button';
import { FiMenu, FiX, FiChevronUp } from 'react-icons/fi';
import { Box } from '../Box';
import { Logo, LogoResponsive } from '../Logo';
import { Props, Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "navigation-components"
    }}>{`Navigation Components`}</h1>
    <p>{`HDS exports several components to build nav bars into your app.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#navigationbar"
        }}>{`NavigationBar`}</a>{` just renders the nav bar on top of the page`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#navigation"
        }}>{`Navigation`}</a>{` wrapps `}<inlineCode parentName="li">{`NavigationBar`}</inlineCode>{` and provides the event listeners to toggle a (mobile) nav sheet`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#navigaton-with-menu"
        }}>{`Menu`}</a>{` provides some convenience components to style a default menu.`}</li>
    </ul>
    <p>{`If in doubt, you'll probably want to look at the `}<a parentName="p" {...{
        "href": "#navigaton-with-menu"
      }}>{`Navigation with Menu`}</a>{` examples at the bottom.`}</p>
    <h2 {...{
      "id": "navigationbar"
    }}>{`NavigationBar`}</h2>
    <p>{`NavigationBar renders the default navigation bar at the top of the page.
It has three optional child components, `}<inlineCode parentName="p">{`NavLeft`}</inlineCode>{`, `}<inlineCode parentName="p">{`NavCenter`}</inlineCode>{` and `}<inlineCode parentName="p">{`NavRight`}</inlineCode>{`.`}</p>
    <p>{`NavigationBar doesn't come with a mobile nav or toggle, it'll just render the bar on top of the page and align items nicely.`}</p>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={NavigationBar} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={1} __code={'{/* position prop just for docs*/}\n<NavigationBar position=\"absolute\">\n  <NavLeft>Left</NavLeft>\n  <NavCenter>Center</NavCenter>\n  <NavRight>Right</NavRight>\n</NavigationBar>'} __scope={{
      props,
      DefaultLayout,
      NavigationBar,
      NavLeft,
      NavCenter,
      NavRight,
      Navigation,
      NavToggle,
      NavigationUser,
      MenuToggle,
      MenuSheet,
      MenuButton,
      Button,
      FiMenu,
      FiX,
      FiChevronUp,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {
        /* position prop just for docs*/
      }
  <NavigationBar position="absolute" mdxType="NavigationBar">
    <NavLeft mdxType="NavLeft">Left</NavLeft>
    <NavCenter mdxType="NavCenter">Center</NavCenter>
    <NavRight mdxType="NavRight">Right</NavRight>
  </NavigationBar>
    </Playground>
    <h2 {...{
      "id": "navigation"
    }}>{`Navigation`}</h2>
    <p>{`Navigation renders the default navigation bar at the top of the page and comes with a mobile nav or toggle setup.`}</p>
    <p>{`Pass in the chlidren to render in the `}<inlineCode parentName="p">{`NavBar`}</inlineCode>{` (`}<inlineCode parentName="p">{`NavLeft`}</inlineCode>{`, `}<inlineCode parentName="p">{`NavCenter`}</inlineCode>{`, `}<inlineCode parentName="p">{`NavRight`}</inlineCode>{`) and make sure you render `}<inlineCode parentName="p">{`NavToggle`}</inlineCode>{` somewhere in the children.`}</p>
    <p>{`Pass a prop `}<inlineCode parentName="p">{`menuContent`}</inlineCode>{` to pass in a component with the contents of the toggled menu.
By default the toggled menu is underlaid with an opaque overlay. To disable the overlay, pass a prop `}<inlineCode parentName="p">{`withOverlay=false`}</inlineCode></p>
    <h3 {...{
      "id": "props-1"
    }}>{`Props`}</h3>
    <Props of={Navigation} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={3} __code={'{/* position prop just for docs*/}\n<Navigation\n  position=\"absolute\"\n  menuContent={\n    <Box\n      width=\"80%\"\n      height=\"100%\"\n      bg=\"danger.light\"\n      position=\"absolute\"\n      right={0}\n      padding=\"large\"\n    >\n      <NavToggle>\n        <Button\n          variant=\"subtle\"\n          context=\"whiteout\"\n          padding={[\'xsmall\', null, \'small\']}\n        >\n          <FiX />\n        </Button>\n      </NavToggle>\n    </Box>\n  }\n>\n  <NavLeft>Left</NavLeft>\n  <NavCenter>Center</NavCenter>\n  <NavRight>\n    <NavToggle>\n      <Button variant=\"subtle\">\n        <FiMenu />\n      </Button>\n    </NavToggle>\n  </NavRight>\n</Navigation>'} __scope={{
      props,
      DefaultLayout,
      NavigationBar,
      NavLeft,
      NavCenter,
      NavRight,
      Navigation,
      NavToggle,
      NavigationUser,
      MenuToggle,
      MenuSheet,
      MenuButton,
      Button,
      FiMenu,
      FiX,
      FiChevronUp,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {
        /* position prop just for docs*/
      }
  <Navigation position="absolute" menuContent={<Box width="80%" height="100%" bg="danger.light" position="absolute" right={0} padding="large" mdxType="Box">
        <NavToggle mdxType="NavToggle">
          <Button variant="subtle" context="whiteout" padding={['xsmall', null, 'small']} mdxType="Button">
            <FiX mdxType="FiX" />
          </Button>
        </NavToggle>
      </Box>} mdxType="Navigation">
    <NavLeft mdxType="NavLeft">Left</NavLeft>
    <NavCenter mdxType="NavCenter">Center</NavCenter>
    <NavRight mdxType="NavRight">
      <NavToggle mdxType="NavToggle">
        <Button variant="subtle" mdxType="Button">
          <FiMenu mdxType="FiMenu" />
        </Button>
      </NavToggle>
    </NavRight>
  </Navigation>
    </Playground>
    <h2 {...{
      "id": "navigation-with-menu"
    }}>{`Navigation with Menu`}</h2>
    <p>{`For convenience there are three extra components to render a standard hamburger menu: `}<inlineCode parentName="p">{`MenuToggle`}</inlineCode>{`, `}<inlineCode parentName="p">{`MenuSheet`}</inlineCode>{` and `}<inlineCode parentName="p">{`MenuButton`}</inlineCode>{`.`}</p>
    <Playground __position={4} __code={'() => {\n  /*\n  It\'s good practice to define menuContent in a variable outside\n  and pass it in to Navigation as a prop\n  */\n  const menuContent = (\n    <MenuSheet>\n      {/*\n    MenuButtons are just buttons with some props pre-set\n    Use them the same way as you would use a Button\n    Or use a button directly, look at the React Inspector,\n    and you see it renders Buttons all along.\n    */}\n      <MenuButton children=\"Item one\" />\n      <MenuButton>Item two</MenuButton>\n      <Button\n        variant=\"subtle\"\n        context=\"whiteout\"\n        size=\"large\"\n        children=\"Item three\"\n        paddingLeft={0}\n      />\n    </MenuSheet>\n  )\n  /* position prop just for docs */\n  return (\n    <Navigation menuContent={menuContent} position=\"absolute\">\n      <NavLeft>\n        <LogoResponsive />\n      </NavLeft>\n      <NavRight>\n        <MenuToggle />\n      </NavRight>\n    </Navigation>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      NavigationBar,
      NavLeft,
      NavCenter,
      NavRight,
      Navigation,
      NavToggle,
      NavigationUser,
      MenuToggle,
      MenuSheet,
      MenuButton,
      Button,
      FiMenu,
      FiX,
      FiChevronUp,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        /*
        It's good practice to define menuContent in a variable outside
        and pass it in to Navigation as a prop
        */
        const menuContent = <MenuSheet mdxType="MenuSheet">
    {
            /*
             MenuButtons are just buttons with some props pre-set
             Use them the same way as you would use a Button
             Or use a button directly, look at the React Inspector,
             and you see it renders Buttons all along.
             */
          }
      <MenuButton children="Item one" mdxType="MenuButton" />
      <MenuButton mdxType="MenuButton">Item two</MenuButton>
      <Button variant="subtle" context="whiteout" size="large" children="Item three" paddingLeft={0} mdxType="Button" />
    </MenuSheet>;
        /* position prop just for docs */

        return <Navigation menuContent={menuContent} position="absolute" mdxType="Navigation">
        <NavLeft mdxType="NavLeft">
          <LogoResponsive mdxType="LogoResponsive" />
        </NavLeft>
        <NavRight mdxType="NavRight">
          <MenuToggle mdxType="MenuToggle" />
        </NavRight>
      </Navigation>;
      }}
    </Playground>
    <h2 {...{
      "id": "navigationuser"
    }}>{`NavigationUser`}</h2>
    <p>{`Component to render the user avatar and name for use in a navigation bar.`}</p>
    <p>{`NavigationUser has a`}</p>
    <ul>
      <li parentName="ul">{`Required prop for `}<inlineCode parentName="li">{`user`}</inlineCode>{` that needs the `}<inlineCode parentName="li">{`profile`}</inlineCode>{` object (containing `}<inlineCode parentName="li">{`first_name`}</inlineCode>{`, `}<inlineCode parentName="li">{`last_name`}</inlineCode>{`, `}<inlineCode parentName="li">{`image_url`}</inlineCode>{`)`}</li>
    </ul>
    <Playground __position={5} __code={'() => {\n  const user = {\n    profile: {\n      first_name: \'James\',\n      last_name: \'Dean\',\n      image_url:\n        \'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg\',\n    },\n  }\n  return <NavigationUser user={user} />\n}'} __scope={{
      props,
      DefaultLayout,
      NavigationBar,
      NavLeft,
      NavCenter,
      NavRight,
      Navigation,
      NavToggle,
      NavigationUser,
      MenuToggle,
      MenuSheet,
      MenuButton,
      Button,
      FiMenu,
      FiX,
      FiChevronUp,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const user = {
          profile: {
            first_name: 'James',
            last_name: 'Dean',
            image_url: 'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg'
          }
        };
        return <NavigationUser user={user} mdxType="NavigationUser" />;
      }}
    </Playground>
    <h2 {...{
      "id": "composed-user-navigation"
    }}>{`Composed User Navigation`}</h2>
    <Playground __position={6} __code={'() => {\n  const user = {\n    profile: {\n      first_name: \'James\',\n      last_name: \'Dean\',\n      image_url:\n        \'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg\',\n    },\n  }\n  return (\n    <Box>\n      <NavigationBar position=\"absolute\" display=\"flex\">\n        <NavLeft>\n          <NavigationUser user={user} />\n        </NavLeft>\n        <NavRight>\n          <Button variant=\"outline\">\n            <FiChevronUp size={12} /> Details\n          </Button>\n        </NavRight>\n      </NavigationBar>\n    </Box>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      NavigationBar,
      NavLeft,
      NavCenter,
      NavRight,
      Navigation,
      NavToggle,
      NavigationUser,
      MenuToggle,
      MenuSheet,
      MenuButton,
      Button,
      FiMenu,
      FiX,
      FiChevronUp,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const user = {
          profile: {
            first_name: 'James',
            last_name: 'Dean',
            image_url: 'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg'
          }
        };
        return <Box mdxType="Box">
        <NavigationBar position="absolute" display="flex" mdxType="NavigationBar">
          <NavLeft mdxType="NavLeft">
            <NavigationUser user={user} mdxType="NavigationUser" />
          </NavLeft>
          <NavRight mdxType="NavRight">
          <Button variant="outline" mdxType="Button">
            <FiChevronUp size={12} mdxType="FiChevronUp" /> Details
          </Button>
          </NavRight>
        </NavigationBar>
      </Box>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      